import { NODE_ENV, STUDIO_DOMAIN } from '@/constants'
import _escapeRegExp from 'lodash/escapeRegExp'

export function validateCurrentDomain() {
  if (NODE_ENV !== 'development') {
    const escapedStudioDomain = _escapeRegExp(STUDIO_DOMAIN)
    const redirectUriValidationRegex: RegExp = new RegExp(
      `^https?:\\/\\/[^.\\s]+\\.${escapedStudioDomain}$`,
    )

    const origin = window.location.origin

    if (!redirectUriValidationRegex.test(origin)) {
      const errorMsg = `Domain Validation Failed: Studio can not be run from ${origin}`
      alert(errorMsg)
      throw new Error(errorMsg)
    }
  }
}
