import {
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  BASE_PATH,
  ROUTE_INITIAL,
} from '@/constants'
import { authPlugin, AuthServiceOptions } from '@orbica/vue-modules'
import Vue from 'vue'
import router from '../router'

if (!AUTH0_DOMAIN || !AUTH0_CLIENT_ID || !AUTH0_AUDIENCE) {
  throw new Error('No Auth0 options provided')
}

const authOptions: AuthServiceOptions = {
  domain: AUTH0_DOMAIN,
  clientId: AUTH0_CLIENT_ID,
  audience: AUTH0_AUDIENCE,
  redirectUri: `${window.location.origin}${BASE_PATH}callback`,
  onRedirectCallback: (appState) =>
    router.push(appState && appState.targetUrl ? appState.targetUrl : { name: ROUTE_INITIAL }),
}

Vue.use(authPlugin, authOptions)
